/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
import withWrapper from '../components/wrapper.js';
import { Helmet } from "react-helmet";
//import screenshot from '../images/screenShot_sm.png';
//import big_ben from '../images/big_ben.jpg';
//import down_flourish from '../images/down_flourish.png';

import Img from "gatsby-image";
import BackgroundImage from 'gatsby-background-image';

function _inner (props) {
  return (
        <div>
        <style>{`
          li {
            margin-bottom: 1px;
          }
          .tricols {
              max-width:33.33%;
              
              float:left;
          }
          p {
              -webkit-hyphens: auto;
              -moz-hyphens: auto;
              -ms-hyphens: auto;
              hyphens: auto;
              text-align:justify;
              
          }
          li {
              font-size:18px;
          }
        `}
        </style>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Valuation Methods</title>
                <link rel="canonical" href="https://www.valtrace.com/valuationMethods/" />
                <html lang="en" />
            </Helmet>
            <div class="underlay" style={{opacity:'1',zIndex:"-10",position:'absolute',top:'115px',height:'280px',left:'0',right:'0'}}>
                <BackgroundImage
                  Tag="section"
                  fluid={props.data.big_ben.childImageSharp.fluid}
                  backgroundColor={`#eee`}
                  style={{backgroundRepeat:'no-repeat', width: '100%', height:'100%',backgroundSize:'100%',backgroundPosition: 'center center'}}
                />
                <div style={{position:'absolute',top:'0',bottom:'0',right:'0',left:'0',opacity:'.3',backgroundColor:'#000'}} />
            </div>
            <div class="underlay" style={{opacity:'1',zIndex:"-10",position:'absolute',top:'395px',height:'30px',left:'0',right:'0'}}>
              <center><div style={{width:'100px',height:'30px'}}>
                  <BackgroundImage
                    Tag="section"
                    fixed={props.data.down_flourish.childImageSharp.fixed}
                    backgroundColor={`#eee`}
                    style={{backgroundSize: '60px 30px',backgroundRepeat: 'no-repeat',backgroundPosition: 'center top'}}
                  />
                </div></center>
            </div>
            <div style={{position:'relative',top:'0px',height:'280px',marginBottom:'20px', display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                <div style={{flexBasis:'50%',textAlign:'center',color:'white',fontSize: '23px',textShadow:'0 0 3px rgba(0,0,0,.5)',fontWeight:'500'}}>
                    <div>World's most advanced</div>
                    <div>automated valuation system</div>
                </div>
                <div style={{flexBasis:'50%',padding:'10px'}}>
                    <center><a href='/createCompany' style={{textDecoration: 'none'}}><div style={{width: '12em',border: '1px solid rgba(255,255,255,.75)', borderRadius: '4px', backgroundColor: 'black', color: 'white', fontSize:'20px',padding: '10px 28px',margin:'10px'}}>Value a Business</div></a></center>
                    <div style={{color:'white',fontSize:'22px'}}><center>...it's free!</center></div>
                </div>
            </div>
            <div style={{color:'black',paddingTop:'30px'}}>
                <h1>How to Value a Business</h1>
                <p>The market value of a business is exactly what it sounds like.  It is the highest price that someone will pay for the business.  If you are a public company, you can get a good estimate of this by continually selling small pieces of it.  For a private company, you sometimes have sales events that give you a snapshot in time.  If you are a private company and need to find a value for it, you have a number of options.</p>
                <div className='tricols' style={{paddingRight:'12px'}}>
                    <h3 style={{textAlign: 'center',letterSpacing: '0.01em',fontSize: '1.05em'}}>Comparables</h3>
                    <p>This entails finding what similar businesses have sold for.  If two company’s financials are similar then their market values will be similar. These experts focus on important ratios such as price/earnings, price/revenue or the like. If the comparable business’s financials are close to a multiple, then the economies of scale cost savings can be accounted for. Precedent transactional analysis is another name for this form of valuation.</p>
                </div>
                <div className='tricols' style={{paddingLeft:'6px',paddingRight:'6px'}}>
                    <h3 style={{textAlign: 'center',letterSpacing: '0.01em',fontSize: '1.05em'}}>Regression</h3>
                    <p>This method finds a group of similar businesses and then uses linear regression to value a business.  Linear regression finds a statistical “best fit” using many different ratios.  It is typically much better than a simple comparables method.  Experts often augment these methods with their own experience to get an even better estimate.</p>
                </div>
                <div className='tricols' style={{paddingLeft:'12px'}}>
                    <h3 style={{textAlign: 'center',letterSpacing: '0.01em',fontSize: '1.05em'}}>Neural Network</h3>
                    <p>Linear regression can only do so much.  And valuation depends as much on trends and patterns as it does ratios, so a neural network is a good choice as an estimator.</p>
                    <p>At Valtrace, we started with the regression method and then moved on the Neural Network method.  The neural network yields about a 30-40% reduction in the estimation error over the regression method.</p>
                </div>
                <div style={{clear:'both',paddingTop:'10px'}}>
                    <h2>About Valtrace</h2>
                    <p>Valtrace is a free business valuation utility.  We use a custom neural network that has been trained on public company financials.</p>
                    <div style={{clear:'both',float: 'left',paddingTop: '20px',paddingBottom: '20px',paddingRight: '40px', width: '45%'}} >
                      <div style={{width:'373px',height:'177px',margin:'0'}} >
                        <Img alt='Valtrace Screenshot' style={{height: '100%',width: '100%' }} imgStyle={{height:'100%', width:'100%'}} alt='Valtrace' fixed={props.data.screenShot.childImageSharp.fixed} />
                      </div>
                      <div style={{textAlign:'center',width:'100%',fontSize:'80%',lineHeight: '1.3em'}}>
                        <span style={{fontWeight:'500'}}>Valtrace</span>
                        <span> — business valuation utility.</span>
                      </div>
                    </div>
                    <div style={{float:'left',width:'55%'}}>
                        <p>The process of valuing your business with Valtrace is simple:</p>
                        <ul>
                            <li><a href="/createCompany" style={{color:'blue'}}>Sign up</a></li>
                            <li>Enter financial data</li>
                                <p style={{paddingLeft:'20px'}}>Valtrace can value a business with as little as one quarter of financial data, but the valuation estimate improves with more data.</p>
                            <li>Get a valuation in real time</li>
                        </ul>
                    </div>
                    <div>
                        <p>Since the value is in realtime you can test different numbers to see how they affect your valuation.  You can use these insights to set your financial goals before a sale.</p>
                        <p>If you want to see how it works, we make it easy to look at the financials and valuations of public companies.</p>
                    </div>
                    <div style={{clear:'both',paddingTop:'10px'}}>
                        <center><a href='/createCompany' style={{textDecoration:'none'}}><div style={{width: '11em',border: '1px solid black', borderRadius: '4px', backgroundColor: 'black', color: 'white', padding: '7px 25px',margin:'10px'}}>Value a Business</div></a></center>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default withWrapper(_inner,{});

//import images
export const query = graphql`
  query {
    screenShot: file(relativePath: { eq: "screenShot_sm.png" }) {
      childImageSharp {
        fixed(width: 373,height: 177) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    big_ben: file(relativePath: { eq: "big_ben.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    down_flourish: file(relativePath: { eq: "down_flourish.png" }) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 30) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }`

